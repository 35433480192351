import React from 'react';
import './WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const handleClick = () => {
    window.open('/link.php?link=wa');
  };
  const handleClick2 = () => {
    window.open('/link.php?link=tg');
  }; 
  
  return (
  <>
    <div className="whatsapp-icon" onClick={handleClick}><svg height="512" fill="#fff" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="OUTLINE_copy"><g><path d="m15.338 15.833c-.421-.389-.888-.935-1.091-1.419-.031-.062-.093-.218 0-.343.187-.249.218-.265.421-.499.14-.172.249-.327.203-.468l-.701-1.824c-.063-.125-.249-.14-.468-.156-.187 0-.389 0-.53.031-.312.109-.95.951-1.028 1.559-.172 1.325 1.028 3.087 2.228 4.178 1.2 1.107 3.054 2.152 4.363 1.855.608-.14 1.387-.842 1.449-1.169.031-.14.016-.343-.016-.53-.016-.218-.062-.374-.187-.436l-1.589-.733c-.141-.078-.281-.016-.421.156-.171.234-.25.327-.452.577-.109.14-.187.234-.311.249-.141.031-.327-.047-.359-.047-.499-.17-1.075-.591-1.511-.981zm-5.236 5.598.904-2.292c-.998-1.154-1.606-2.667-1.606-4.319 0-3.648 2.961-6.595 6.608-6.595 3.646 0 6.591 2.947 6.591 6.595s-2.945 6.611-6.591 6.611c-1.465 0-2.805-.468-3.896-1.278zm-1.107-2.246-1.247 4.989 4.457-2.027c1.138.592 2.431.936 3.803.936 4.55 0 8.243-3.695 8.243-8.264 0-4.552-3.694-8.248-8.243-8.248-4.566 0-8.259 3.695-8.259 8.248 0 1.607.467 3.104 1.246 4.366z" fill="#fff"/></g><g><g id="Shopping_10_101_"><g><g><g><g><path d="m16 1c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15m0-1c-8.837 0-16 7.164-16 16s7.164 16 16 16 16-7.163 16-16-7.164-16-16-16z" fill="#fff"/></g></g></g></g></g></g></g></svg></div>
    <div className="tg-icon" onClick={handleClick2} dangerouslySetInnerHTML={{__html: '<svg clip-rule="evenodd" fill-rule="evenodd" height="512" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"><g id="Layer_x0020_1"><path d="m256 .09c141.33 0 255.91 114.58 255.91 255.91s-114.58 255.91-255.91 255.91-255.91-114.58-255.91-255.91 114.58-255.91 255.91-255.91zm85.72 351.11c-.02.05-7.42 18.52-27.27 9.86-10.79-4.72-77.95-59.44-92.97-70.94 29.26-26.3 58.52-52.6 87.76-78.93l-109.82 69.74c-17.97-6.05-35.95-12.06-53.9-18.15-16.01-5.14-17.84-22.95.06-29.73l208.53-81.82c2-.85 25.22-9.68 25.22 10.96zm-11.08-4.15 36.84-185.76c-.62-2.75-8.87.83-8.89.84l-209.03 81.98c-6.53 2.17-6.69 5.23-.18 7.55l48.44 16.31 130.36-82.76c4.43-2.7 11.11-4.61 14.98.04 3.91 4.71.63 10.5-3.17 14.05-3.76 3.51-77.52 69.83-100.07 90.1l79.85 61.12c7.01 2.71 10.1-1.99 10.87-3.47zm-74.64-335.14c-134.81 0-244.1 109.28-244.1 244.09s109.29 244.09 244.1 244.09 244.1-109.28 244.1-244.09-109.29-244.09-244.1-244.09z" fill-rule="nonzero"/></g></svg>'}} ></div>    
  </>     
  );
};

export default WhatsAppIcon;